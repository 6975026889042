import * as React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import { CtaSimple } from '../components/ctaSimple'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <CtaSimple
        title="Privacy Policy"
        helperText="Last Updated: 10/10/2022"
        titleSize="3xl"
        pageHeader
      />
    </Box>
    <Box
      as="section"
      maxW={{
        base: 'xl',
        md: '7xl',
      }}
      mx="auto"
      px={{
        base: 4,
        md: 8,
      }}
      py={{
        base: 10,
        md: 24,
      }}
    >
      <Heading as="h2" size="xl" mb={12}>
        Privacy Statement for Synapse Studios, LLC dba CarbonQA (“CarbonQA”, “company”, “we”, “us”)
      </Heading>
      <Text>
        We are firmly committed to protecting the privacy of visitors to our website – carbonqa.com. 
        This Privacy Statement outlines our web information-gathering and distribution practices. 
        By submitting your information via our website, you agree to our use of that information as described in this statement. 
        Please read our Terms & Conditions for additional information.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Minors
      </Heading>
      <Text>
        We understand the importance of protecting children's privacy. 
        We consider anyone under the age of 18 to be a minor and therefore not eligible for our services. 
        We ask that no one under the age of 18 submit any personal information to us.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        IP Addresses and Cookies
      </Heading>
      <Text>
        We use your IP address to help diagnose problems with our server, and to administer our website. 
        Your IP address is also used to gather broad demographic information.
      </Text>
      <Text>
        Cookies are small pieces of text stored on your computer (not our site) that help us know which browser you're using and where you've been on our site. 
        Most cookies are "session cookies" which means they're automatically deleted at the end of each session. 
        A cookie itself does not have the ability to automatically collect personal information about you. 
        A cookie can store certain information that identifies your computer to us so that you don't have to re-enter information as frequently when you use our site.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Your Requests for Information
      </Heading>
      <Text>
        CarbonQA uses your personal information to fulfill your requests for info and to review and consider your service-related inquiries. 
        We may keep any of your personal details on file and use them to contact you for communication purposes. 
        We may also use your personal information in analyzing website user behavior in order to measure interest in specific items.
      </Text>
      <Text>
        If you fill out our "Contact" page, you may be required to enter your name, company, phone number and e-mail address. 
        We will use this data to provide you with more information about CarbonQA and its services.
      </Text>
      <Text>
        If you send us emails or letters, or if third parties send us correspondence about your activities or postings on our site, we may collect that information into a file and use it to resolve disputes, troubleshoot problems and enforce our Terms of Use agreement.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Disclosure Policy
      </Heading>
      <Text>
        This Privacy Statement authorizes us to disclose any information about you to law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us to legal liability.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Links
      </Heading>
      <Text>
        For your convenience, our site contains links to other websites of interest. We are not responsible for the privacy practices or the content of the linked sites.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Security
      </Heading>
      <Text>
        We have security measures in place on our site to protect the loss, misuse and alteration of the information under our control. We store and encrypt all user information on our server; however, we cannot guarantee unauthorized access as there is no such thing as flawless internet security.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Questions
      </Heading>
      <Text>
        If you have questions about this Privacy Statement or would like to be removed from our database or future correspondence please contact us at privacy@synapsestudios.com.
      </Text>

      <Heading as="h4" size="l" mt={12}>
        By using our website, you agree to the collection, use, and storage of your information by us in the manner described in this Privacy Statement and elsewhere on the website. 
        We reserve the right to make changes to this policy at our discretion.
      </Heading>
    </Box>
  </Layout>
)

export default PrivacyPage
